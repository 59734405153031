<template>
  <select v-bind:id="itemboxname" data-placeholder="Select Item" class="form-control" ref="item" v-model="item.code" @change="itemChanged(item.code)" >
    <option value="0" selected disabled></option>
    <option v-for="[key, item] in items" v-bind:value="item.code">
      {{ item.code }} - {{ item.name }}
    </option>
  </select>
</template>

<script>
  import { userService } from '@/store/auth-header.js'
  export default {
    name: 'ItemBox',
    props: {
      myitem: {
        type: Object,
        required: false,
        default: () => JSON.parse('{"id":"","code":0,"nature":0,"ledger_group":{"id":"","code":0,"status":0,"type":0,"name":""},"name":"","p_name":"","process":{"id":"","code":0,"name":""},"group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0},"subunit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"category":{"id":"","code":0,"name":"","p_name":""},"brand":{"id":"","code":0,"name":"","print_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"unit":{"id":"","code":0,"name":"","digits":0},"rate":0,"min_qty":0,"max_qty":0,"wgt":0}')
      },
      myitems: {
        type:Map,
        default: function () {
          return new Map();
        }
      }
    },
    beforeMount () {
      this.items = this.myitems;
      this.item = this.myitem;
      $('#'+this.$data.itemboxname).val(this.myitem.code);
    },
    data () {
      return {
        itemboxname: 'itembox' + String(parseInt(Math.random() * 1000) +1),
        items: new Map(),
        item: {"id":"","code":0,"nature":0,"ledger_group":{"id":"","code":0,"status":0,"type":0,"name":""},"name":"","p_name":"","process":{"id":"","code":0,"name":""},"group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0},"subunit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"category":{"id":"","code":0,"name":"","p_name":""},"brand":{"id":"","code":0,"name":"","print_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"unit":{"id":"","code":0,"name":"","digits":0},"rate":0,"min_qty":0,"max_qty":0,"wgt":0}
      }
    },
    beforeCreate(){
    },
    created () {

    },
    mounted () {
      let self = this;

      $('#'+this.$data.itemboxname).select2({
        allowClear:true,
        closeOnSelect: true,
        placeholder: {
          id: '', // the value of the option
          text: 'None Selected'
        }
      });

      $('#'+this.$data.itemboxname).on("select2:select", function (e) {
        var data = e.params.data;
        self.$data.item.code = parseInt(data.id);
        self.itemChanged( parseInt(data.id));
      });

      if(!_.isNull($('#'+this.$data.itemboxname).val())){
        // self.itemChanged(parseInt($('select').val()));
      }
    },
    destroyed: function(){
      $('#'+this.$data.itemboxname).select2('destroy');
    },
    updated () {
      //console.log('updated');
      // $(this.$refs.item).select2('refresh')
    },
    watch:{
      items: function (newitems) {

      }
    },
    methods: {

      itemChanged(code){
        //console.log('itemchaned :'+code)
        this.$data.item =  JSON.parse(JSON.stringify(this.$data.items.get(code)));
        this.$emit('itemchanged', this.$data.items.get(code));
      },

    }
  }
</script>

<style scoped>

</style>
